import { format } from "date-fns"

import { EmberApiError, parseError } from "api/errors"

import { DateRange } from "components/generic/date-time/ember-date-range-picker"

import { ServiceProvider } from "types/activity"
import { Unsubscribable } from "types/observable"
import { ShiftPatch } from "types/shift-detail"
import { RotaVisibilityDate, ShiftDetails } from "types/shift-detail"

import { formatDateForQuery } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"
import { sortByKeys } from "utils/struct-utils"

interface fetchServiceProvidersParams {
  onSuccess: (serviceProviders: ServiceProvider[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchServiceProviders({
  onSuccess,
  onError,
}: fetchServiceProvidersParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/service-providers/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface fetchServiceRotaVisibilityDateParams {
  onSuccess: (rotaVisibilityDate: RotaVisibilityDate) => void
  onError: (error: EmberApiError) => void
}

export function fetchRotaVisibilityDate({
  onSuccess,
  onError,
}: fetchServiceRotaVisibilityDateParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/staff/rota/visibility-date/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface fetchShiftsParams {
  dateRange: DateRange
  employeeUid: string
  allShifts?: boolean
  flatResponse?: boolean
  onSuccess: (shifts: ShiftDetails[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchShifts({
  dateRange,
  employeeUid,
  allShifts,
  flatResponse,
  onSuccess,
  onError,
}: fetchShiftsParams): Unsubscribable {
  const query = new URLSearchParams({
    scheduled_end_time_from: formatDateForQuery(
      format(dateRange.start, "yyyy-MM-dd")
    ).from,
    scheduled_start_time_to: formatDateForQuery(
      format(dateRange.end, "yyyy-MM-dd")
    ).to,
    assigned_driver: employeeUid,
    all_shifts: allShifts ? "true" : "false",
    flat_response: flatResponse ? "true" : "false",
  }).toString()
  return fetchFromAPIBase({
    path: `/v1/shifts/?${query}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      response as Array<ShiftDetails>
      const sortedResult = sortByKeys(response, [
        (s: ShiftDetails) => s.scheduledStart,
        (s: ShiftDetails) => s.scheduledEnd,
      ])
      onSuccess(sortedResult)
    } else {
      onError(parseError(response))
    }
  })
}

export const patchShiftDetails = ({
  field,
  newValue,
  shift,
  onSuccess,
  onError,
}) => {
  const shiftData: ShiftPatch = {
    id: shift.id,
  }

  if (newValue.trim() === "") {
    shiftData[field] = null
  } else {
    shiftData[field] = newValue
  }

  fetchFromAPIBase({
    path: "/v1/shifts/",
    method: "PATCH",
    body: { updateShifts: [shiftData] },
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response && response[0])
    } else {
      onError(parseError(response))
    }
  })
}
