// @ts-strict-ignore
import { useEffect, useState } from "react"

import { EmberApiError, parseError } from "api/errors"

import { Unsubscribable } from "types/observable"
import { Order } from "types/order"
import { Balance, DriverDetails, PersonDetails, Profile } from "types/person"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { useGlobalState } from "utils/state-utils"

// This is absurd (we're recursively importing this very file) but it lets us
// mock the call from `useBalance` to `fetchPersonDetails`.
// See https://stackoverflow.com/a/47976589/11873851
import * as personApi from "./person"

// `searchPeople`

export interface PeopleSearchRequest {
  name?: string
  email?: string
  filter?: string
  limit?: number
  onlyDrivers?: boolean
  onlyStaff?: boolean
  roleNames?: string[]
}

interface searchPeopleParams {
  request: PeopleSearchRequest
  onSuccess: (people: Profile[]) => void
  onError: (error: EmberApiError) => void
}

export function searchPeople({
  request = {},
  onSuccess,
  onError,
}: searchPeopleParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/people/${getQueryString(request)}`,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

// `fetchPersonDetails` -- fetch the `PersonDetails` record for any user

export interface fetchPersonDetailsParams {
  personUid: string
  passengerMessageLimit?: number
  onSuccess: (details: PersonDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchPersonDetails({
  personUid,
  passengerMessageLimit = null,
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchPersonDetailsParams): Unsubscribable {
  const query = getQueryString({
    limitPassengerMessages: passengerMessageLimit,
  })
  return fetchFromAPIBase({
    path: `/v1/people/${personUid}/${query}`,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

export enum TicketListType {
  ACTIVE = "active",
  EXPIRED = "expired",
  ANY = "any",
}

export interface FetchAccountOrdersResponse {
  orders: Order[]
  totalPages: number
}

interface fetchAccountOrdersParams {
  limit?: number
  page?: number // Page number, starting from 1
  personUid?: string
  type?: TicketListType
  onSuccess: (res: FetchAccountOrdersResponse) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchAccountOrders({
  limit = null,
  page = null,
  personUid = null,
  type = null,
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchAccountOrdersParams): Unsubscribable {
  const query = getQueryString({
    limit: limit,
    page: page,
    type: type,
  })
  return fetchFromAPIBase({
    path: `/v1/accounts/${personUid}/orders/${query}`,
    authRequired: true,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

// `fetchDriverDetails` -- fetch the `DriverDetails` record for a given driver

interface fetchDriverDetailsParams {
  driverUid: string
  onSuccess: (details: DriverDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
  queryParams?: object
}

export function fetchDriverDetails({
  driverUid,
  onSuccess,
  onError,
  onCompletion = () => null,
  queryParams = { all: true },
}: fetchDriverDetailsParams): Unsubscribable {
  const query = getQueryString(queryParams)
  return fetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/${query}`,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

// `useBalance` -- load the store credit balance for a given user

interface useBalanceParams {
  personUid: string
  setError: (error: EmberApiError) => void
  adminUser: boolean
}

export function useBalance({
  personUid,
  setError,
  adminUser,
}: useBalanceParams): Balance {
  const { loggedInPersonUid, balance: globalBalance } = useGlobalState()
  const [balance, setBalance] = useState<Balance>()

  const emptyBalance = {
    cashable: 0,
    non_cashable: 0,
    total: 0,
  }

  useEffect(() => {
    if (!personUid) {
      return
    }
    if (personUid == loggedInPersonUid) {
      // If the customer whose balance we want is the logged in user, no need
      // to fetch, we already have their balance in global state
      setBalance(globalBalance ?? emptyBalance)
      return
    }
    if (!adminUser) {
      // If you're not an admin user, the call below is going to fail because
      // it's an admin-only endpoint. This happens e.g. when a user is making
      // changes without being logged in. They have no credit.
      setBalance(emptyBalance)
      return
    }
    const sub = personApi.fetchPersonDetails({
      personUid,
      // We're not interested in the orders at all here, but setting passengerMessageLimit=0 is
      // like not setting a limit. So this is the least we can ask for:
      passengerMessageLimit: 1,
      onSuccess: (person: PersonDetails) => {
        setBalance(person.balance)
        setError(null)
      },
      onError: (error: EmberApiError) => {
        setBalance(null)
        setError(error)
      },
    })
    return () => sub.unsubscribe()
  }, [personUid, setBalance, setError])

  return balance
}
