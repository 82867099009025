// @ts-strict-ignore
import { useEffect, useState } from "react"

import { MinimalProfile, Person, Profile } from "types/person"

import { useGlobalFetcher } from "utils/state-utils"

type NamedPerson = Profile | Person | MinimalProfile

export function getPersonName(person: NamedPerson): string {
  if (person) {
    if (person.preferredName) {
      return person.preferredName
    } else if (person.name) {
      return person.name
    } else if ("email" in person) {
      // `email` is not in the `MinimalProfile` type
      return person.email
    } else {
      return person.uid
    }
  }
}

type NameShortener = (person: NamedPerson) => string

/**
 * These are the namespaces that can be used for the name shortener. Basically any global fetcher
 * that returns an array of `Profile` objects. See `FetchersType` in `state-utils.tsx` for the list
 * of all global fetchers.
 */
type PersonNamespace = "usersAssignableToIssues" | "chatUsers" | "drivers"

/**
 * Returns a `NameShortener`, which is a function that takes a person object (a `NamedPerson`) and
 * returns a short name for them, as a string. If unambiguous, the short name will be their first
 * name only. If more than one person shares the same first name, the last name initial is added,
 * and if that's still ambiguous, the name is not shortened at all (i.e. if we can't shorten the
 * name unambiguously, the shortener function returns the same as `getPersonName`).
 *
 * The hook function needs to be given a `PersonNamespace`, which is the set of users that we'll
 * consider for disambiguation. So if e.g. "drivers" is passed, we'll fetch the list of all drivers,
 * the NameShortener function will return a shortened name that's been compared with all other
 * drivers' names to avoid ambiguity.
 *
 * If a person object that was not part of the original namespace is passed to the NameShortener
 * (e.g. you use "drivers" as a namespace but then pass to the shortener a person that isn't a
 * driver), the person's full, unabbreviated name is returned.
 */
export function useNameShortener(namespace: PersonNamespace): NameShortener {
  const { data: assignableUsers } = useGlobalFetcher(namespace)
  const [shortNames, setShortNames] = useState<Record<string, string>>({})

  useEffect(() => {
    if (!assignableUsers) {
      return
    }

    // First, if unambiguous, use prefix only
    const grouped: Record<string, Profile[]> = {}
    for (const person of assignableUsers) {
      const match = getPersonName(person).match(/^\S+/)
      const key = match ? match[0] : getPersonName(person)
      ;(grouped[key] ||= []).push(person)
    }

    // If the first name is ambiguous, use first name + initial of last name
    for (const [key, group] of Object.entries(grouped)) {
      if (group.length > 1) {
        delete grouped[key]
        for (const person of group) {
          const match = getPersonName(person).match(/^\S+\s+\S/)
          const subkey = match ? `${match[0]}.` : getPersonName(person)
          ;(grouped[subkey] ||= []).push(person)
        }
      }
    }

    const shortNames: Record<string, string> = {}
    for (const key of Object.keys(grouped)) {
      // Any names still ambiguous are removed -- we'll have to use full names for them
      if (grouped[key].length == 1) {
        const [person] = grouped[key]
        shortNames[person.uid] = key
      }
    }

    setShortNames(shortNames)
  }, [assignableUsers])

  return (person: Profile): string =>
    shortNames[person.uid] || getPersonName(person)
}
