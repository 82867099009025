import { Location } from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { sortLocationsByRegionAndDetailedName } from "utils/location-utils"

import { EmberApiError, parseError } from "./errors"

interface LocationSearchRequest {
  type?: string
  includeOrigins?: boolean
  includeDestinations?: boolean
}

interface fetchLocationsParams {
  request: LocationSearchRequest
  onSuccess: (locations: Location[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchLocations({
  request = {
    type: "all",
    includeOrigins: true,
    includeDestinations: true,
  },
  onSuccess,
  onError,
}: fetchLocationsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/locations/${getQueryString(request)}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(sortLocationsByRegionAndDetailedName(response))
    } else {
      onError(parseError(response))
    }
  })
}
