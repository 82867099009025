import React from "react"

import { Auth } from "@aws-amplify/auth/lib/Auth"
import { Amplify } from "@aws-amplify/core"
import { Script } from "gatsby"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

import { OrbitProvider } from "@kiwicom/orbit-components"

import awsauth from "./awsauth.json"
import awsconfig from "./awsconfig.json"
import "./src/styles/fonts/fonts.css"
import LightTheme from "./src/styles/light-theme"
import GlobalContextProvider from "./src/utils/state-utils"

awsconfig.Auth.userPoolId = process.env.GATSBY_COGNITO_USER_POOL_ID
awsconfig.Auth.userPoolWebClientId = process.env.GATSBY_COGNITO_CLIENT_APP_ID

Amplify.configure(awsconfig)
Auth.configure({ oauth: awsauth })

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalContextProvider>
        <OrbitProvider theme={LightTheme}>{element}</OrbitProvider>
      </GlobalContextProvider>
      <Script
        id="front-chat"
        strategy="post-hydrate"
        onLoad={() => console.log("logged")}
        onError={(e) => console.error(e)}
      >{`
      if (!window["Cypress"]){ 
        const js = document.createElement("script");
        js.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
        js.onload = function () { 
          window.FrontChat("init", {
            chatId: "9c8642a13390a4cb7fd5f98ca817aab0",
            useDefaultLauncher: true,
          }) 
        };
        document.head.appendChild(js);
      }
    `}</Script>
    </>
  )
}
