import { EmberApiError, parseError } from "api/errors"

import { Unsubscribable } from "types/observable"
import { VehicleData, VehicleModel, VehicleSummary } from "types/vehicle"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { sortBy } from "utils/struct-utils"

interface fetchVehiclesOptions {
  audience?: string
}

export function fetchVehicles({
  options = {},
  onSuccess,
  onError,
}: {
  options: fetchVehiclesOptions
  onSuccess: (vehicles: VehicleData[]) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/vehicles/" + getQueryString(options),
    authRequired: false,
  }).subscribe((response) => {
    if (response && !response.error) {
      const vehicles = response as VehicleData[]
      sortBy(vehicles, vehicleSortKey)
      onSuccess(vehicles)
    } else {
      onError(parseError(response))
    }
  })
}

export function vehicleSortKey(v: VehicleData | VehicleSummary): string {
  // By default, sort by the 2nd group of letters in the reg plate, since that's what we universally
  // use to refer to them
  if (v.plateNumber) {
    const match = /^\w+\s+(\w+)$/.exec(v.plateNumber)
    if (match) {
      return `${match[1]} // ${v.plateNumber}`.toLowerCase()
    } else {
      return v.plateNumber.toLowerCase()
    }
  } else {
    return v.name?.toLowerCase()
  }
}

export function fetchVehicleModels({
  onSuccess,
  onError,
}: {
  onSuccess: (models: VehicleModel[]) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/vehicle-models/",
    authRequired: true,
  }).subscribe((response) => {
    if (response && !response.error) {
      const models = response as VehicleModel[]
      onSuccess(models)
    } else {
      onError(parseError(response))
    }
  })
}
