import { Route } from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase } from "utils/fetch-utils"

import { EmberApiError, parseError } from "./errors"

interface fetchRoutesParams {
  onSuccess: (routes: Route[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchRoutes({
  onSuccess,
  onError,
}: fetchRoutesParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/routes/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}
