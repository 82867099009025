import moment from "moment"

import { EmberSelectOption } from "components/generic/ember-select"

import { Location, LocationType } from "types/location"

import { useGlobalState } from "./state-utils"

export function sortLocationsByRegionAndDetailedName(locations: Location[]) {
  const sorted = locations.sort(function (a, b) {
    // Make sure sort is case insensitive
    const titleA = a.regionName.toLowerCase()
    const descriptionA = a.detailedName.toLowerCase()
    const titleB = b.regionName.toLowerCase()
    const descriptionB = b.detailedName.toLowerCase()
    return titleA < titleB
      ? -1
      : titleA > titleB
      ? 1
      : descriptionA < descriptionB
      ? -1
      : 1
  })
  return sorted
}

export function filterLocationsByTypes(
  locations: Location[],
  types: LocationType[]
) {
  return locations.filter((location) => types.includes(location.type))
}

export function filterLocationsByAllowedOrigin(
  locations: Location[],
  allowedOriginId: number
) {
  return locations.filter((location) =>
    location.allowedOrigins?.includes(allowedOriginId)
  )
}

// Filters out locations that are no longer bookable on any date from today and divides into groups
export function groupLocationsByDate(locations: Location[], date: string) {
  const bookableBeforeDate: Location[] = []
  const bookableOnDate: Location[] = []
  const bookableAfterDate: Location[] = []

  locations.forEach((location) => {
    if (
      location.bookableUntil == null ||
      moment(location.bookableUntil).startOf("day").isSameOrAfter(moment(date))
    ) {
      if (
        location.bookableFrom == null ||
        moment(location.bookableFrom)
          .startOf("day")
          .isSameOrBefore(moment(date))
      ) {
        bookableOnDate.push(location)
      } else {
        bookableAfterDate.push(location)
      }
    } else if (
      moment(location.bookableUntil)
        .startOf("day")
        .isSameOrAfter(moment().startOf("day"))
    ) {
      bookableBeforeDate.push(location)
    }
  })

  return {
    bookableBeforeDate: bookableBeforeDate,
    bookableOnDate: bookableOnDate,
    bookableAfterDate: bookableAfterDate,
  }
}

// Get list of select options for locations suitable for use in a form
export function locationSelectOptions(
  types: LocationType[] = []
): EmberSelectOption[] {
  const {
    fetchers: {
      locations: { data: locations },
    },
  } = useGlobalState()
  const filteredByType =
    types.length == 0 ? locations : filterLocationsByTypes(locations, types)
  const options: EmberSelectOption[] = filteredByType.map(
    locationToSelectOption
  )
  return options
}

// Get list of select options for locations suitable for use in a form
export function fetchLocationOptions(types: LocationType[] = []): Location[] {
  const {
    fetchers: {
      locations: { data: locations },
    },
  } = useGlobalState()
  const filteredByType =
    types.length == 0 ? locations : filterLocationsByTypes(locations, types)

  return filteredByType
}

// Map a location to an EmberSelectOption to be used in a form.
export function locationToSelectOption(location: Location): EmberSelectOption {
  return {
    id: location.id.toString(),
    title: location.regionName,
    description: location.detailedName,
  }
}
